import React from 'react'
import Page from '../components/Page'

const PatentValidityServicesPage = () => (
  <Page title="PATENT VALIDITY SERVICES">
      <p className="mt-8 w-2/3 text-center">
        We assist our customers in:<br/>
        <br/>
      </p>
      <ul className="xl:w-1/2">
        <li className="p-4 mb-2 bg-main">reviewing the validity of the allegedly infringed patent</li>
        <li className="p-4 mb-2 bg-main">discovering relevant prior art (patent, non-patent, and certainly prior art in Chinese language)</li>
        <li className="p-4 mb-2 bg-main">providing the patent proprietor with the first TODOs to proceed against an alleged patent infringer</li>
        <li className="p-4 mb-2 bg-main">delivering court accepted certified translations of prior art and evidence</li>
        <li className="p-4 mb-2 bg-main">performing validity analysis</li>
        <li className="p-4 mb-2 bg-main">developing and elaborating invalidity arguments</li>
      </ul>
  </Page>
)

export default PatentValidityServicesPage